<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-container fluid class="text-center py-6 mb-n2">
          <v-layout wrap row justify-center>
            <v-flex sm10 xs10> </v-flex>
            <v-flex sm2 xs2 class="ml-n8">
              <v-btn icon :color="color.theme" @click="$emit('closerestore')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-text
          class="mt-n8 pb-10 d-flex flex-column justify-space-between align-center"
        >
          <v-img
            max-width="95"
            max-height="75"
            :src="require('../../assets/img/restoresuccess.png')"
          /><br />
          <span :style="titleText">{{
            $t("dialogconfirmdelete.restoresuccess")
          }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data() {
    return {
      loaddataprogress: false,
      password: "",
      showpassword: false,
    };
  },
  props: ["show"],
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:#58A144; font-size: 18px; line-height: 24px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
};
</script>
<style></style>
